// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-d-3-test-2-js": () => import("./../../../src/pages/d3test2.js" /* webpackChunkName: "component---src-pages-d-3-test-2-js" */),
  "component---src-pages-d-3-test-js": () => import("./../../../src/pages/d3test.js" /* webpackChunkName: "component---src-pages-d-3-test-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-rechartstest-js": () => import("./../../../src/pages/rechartstest.js" /* webpackChunkName: "component---src-pages-rechartstest-js" */)
}

